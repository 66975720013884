// =Print Styles
// --------------------------------------------------

*,
*:before,
*:after {
  background-color: #fff !important;
  background-image: none !important;
  color: #000 !important;
}

html {
  font-size: 14px;
}

.secondary-nav,
.main-header__controls,
.main-nav,
.breadcrumb,
.main-footer,
.print {
  display: none;
  visibility: hidden;
}

.wrapper {
  position: relative;
  padding-top: 75px;
}

.main-header {
  position: absolute;
  left: 0;
  top: 0;
}

.logos {
  padding-left: 0;

  .logo {
    width: 120px;
  }

  .logos__text {
    font-size: 3rem;
  }
}

section .grid__item {
  display: block;
}

img,
tr {
  page-break-inside: avoid;
}

.page-confirmation .section p {
  margin-bottom: 0.5em !important;
}

.page-confirmation .shopping-cart {
  .table--listing td,
  .table--listing th {
    padding-left: 0 !important;
  }
}

.adChoices {
  display: none;
}
